#jtool {
  display: grid; 
  grid-template-columns: 260px 1fr; 
  grid-template-rows: 64px 1fr; 
  gap: 0px 0px; 
  grid-template-areas: "header header" "nav content"; 
  height: 100vh;
  overflow: hidden;
  background-color: var(--colorNeutralBackground3);
}

#jtool.collapseNav {
  grid-template-columns: 55px 1fr; 
}

header#header {
  padding: 1px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr auto;
  grid-area: header;
  justify-items: flex-start;
  background-color: var(--colorBrandBackground2);
}

@media (prefers-color-scheme: dark) {
  header#header {
    background-color: var(--colorBrandBackground);
  }
}

header#header > .App-logo {
  display: grid;
  vertical-align: middle;
  align-items: center;
  justify-items: center;
  grid-template-columns: 56px 180px 1fr;
  gap: 10px;
  padding: 2px;
  width: 100%;
}

header#header > .App-logo .App-title {
  display: flex;
  gap: 10px;
  justify-self: flex-start;
  align-items: center;
}

header#header > .App-logo img {
  max-height: 56px;
}

#content {
  grid-area: content;
  overflow: auto;
}

.toolbar {
  position: sticky;
  z-index: 9;
  top: 0;
  box-shadow: var(--shadow8);
  background-color: var(--colorNeutralBackground1);
  border-radius: var(--borderRadiusLarge);
}

section {
  padding: 10px;
}

section:not(.full) {
  padding-left: 40px;
}

section > .fui-Table {
  margin-top: 10px;
}

.stack {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

section:not(.full) > .stack {
  gap: 20px;
  max-width: 60vw/*100ch*/;
}

.stack.hoz {
  flex-direction: row;
  align-items: center;
}
.stack.hoz.wrap {
  flex-wrap: wrap;
}
.stack.hoz.jumplinks {
  gap: 30px;
}

.stack.center>* {
  margin: 0 auto;
}

.stack.filter {
  max-height: 200px;
  overflow: auto;
  gap: 0
}

.props {
  display: grid;
  grid-template-columns: 150px auto;
  gap: 15px;
}

.props>.stack.hoz>:nth-child(even) {
  text-align: right;
  width: 100px;
}

.stretchformcontent {
  display: flex;
  flex-direction: column;
  row-Gap: 16px;
}

.stretchformcontent>div>span {
  justify-self: stretch;
}

.flex {
  display: flex;
}

.flex.spaceevenly {
  justify-content: space-evenly;
}

.tenantTable td,
.tenantTable th {
  width: 30px;
}

.tenantTable th:nth-of-type(1) {
  width: 200px
}

.tenantTable th:nth-of-type(2) {
  width: 150px;
}

.modelTable th.name {
  width: 12vw
}

.modelTable th.tenant {
  width: 10vw
}

.modelTable th.aliases {
  width: 25vw;
}

.osTable th.name {
  width: 20vw
}

.osTable th.refreshed {
  width: 15vw
}

.feedTable th.Refresh {
  width: 10vw;
}

.feedTable th.ostype,
.feedTable th.Enabled {
  width: 8vw;
}

.logtable th.date {
  width: 10vw;
}

.logtable th.type {
  width: 8vw;
}

.fui-Listbox { 
  max-height: 50vh;
}

.fui-AccordionPanel > .stack {
  margin-left: 26px;
} 

pre {
  font-family: monospace !important;
}

.hcTable>thead>tr>th:first-of-type {
  width: 25%
}

.cards {
  gap: 16px;
  row-gap: 16px;
  display: flex;
  flex-direction: column;
}

.cards a:hover {
  text-decoration: none;
}

.cards .fui-CardPreview {
  height: 48px;
  align-self: start;
  padding-top: 10px;
  padding-left: 10px;
  color: var(--colorBrandForeground1);
}

.cards .fui-Card {
  row-gap: 16px;
}

.fui-Card h4 {
  font-size: var(--fontSizeBase400);
  font-weight: var(--fontWeightSemibold);
  line-height: var(--lineHeightBase400);
  margin: 0;
}

.cards .fui-Field.field .fui-Switch, .cards .fui-Field.field .fui-SpinButton, .cards .fui-Field.field .fui-Input {
  grid-area: field;
  align-self: center;
}

.cards .fui-Field.field .fui-Label { 
  grid-area: label;
}

.cards .stack .fui-Field.field .fui-Field__hint {
  grid-area: hint;
}

.smallradius {
  border-radius: 3px;
}

#reports .card .fui-CardPreview {
  max-height: 240px;
  overflow: hidden;
}

.cards .card .fui-CardPreview {
  overflow: hidden;
  max-height: 160px;
}

.fui-TableHeader {
  background-color: var(--colorNeutralStroke2);
  border: calc(var(--borderRadiusLarge)/2) solid var(--colorNeutralStroke2);
  border-radius: var(--borderRadiusLarge);
  box-shadow: var(--shadow8);
  z-index: 10;
}

.scrolling .fui-TableHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.scrolling-toolbar .fui-TableHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
}

.scrolling-toolbar thead.fui-TableHeader {
  top: 38px;
}

.fui-TableBody {
  border-radius: var(--borderRadiusLarge);
  border: 0 solid transparent;
  overflow: hidden;
}

ol.gap li {
  margin-bottom: 10px;
}

.fui-Switch.admin { 
  position: absolute;
  z-index: 11;
  right: 20px;
}

.pageTitle {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--fontSizeHero800) auto auto minmax(var(--fontSizeBase400), auto);
  row-gap: 1em;
  column-gap: 1em;
  grid-template-areas: "breadcrumbs" "title" "description" "accordion";
  margin-bottom: 30px;
}

.pageTitle.withIcon {
  grid-template-columns: auto 1fr;
  grid-template-areas: "breadcrumbs breadcrumbs" "icon title" "description description" "accordion accordion";
}

.pageTitle .icon {
  grid-area: icon;
  display: block;
  align-self: center;
  height: var(--fontSizeHero800);
}

.pageTitle .icon svg, .pageTitle .icon img {
  width: 100%;
  height: 100%;
  align-self: center;
}

.pageTitle h2 {
  line-height: 36px;
  margin-block-start: 0;
  margin-block-end: 0;
}

.tableCellHover { 
  width: 100%;
  height: 100%;
}

.tableCellHover > span {
  padding: 0 13px;
}

.tableCellHover .dd {
  display: none;
}

.tableCellHover:hover > span, .tableCellHover.hold > span {
  display: none;
}

.tableCellHover:hover .dd, .tableCellHover.hold .dd {
  display: inline-flex;
}